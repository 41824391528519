import type { NextPage } from 'next'
import React from 'react'
import { Login } from '~/client/components/login'
import { openSwitchAccountsModal } from '~/client/components/modals'
import { AuthPageWrapper } from '~/client/components/protections'
import { hooks } from '~/client/lib/hooks'
import { usePrefillEmailQueryParam } from '~/client/lib/query-params'

const LoginPage: NextPage = () => {
  const user = hooks.useAuthStore((state) => state.user)
  const urlEmail = usePrefillEmailQueryParam()

  React.useEffect(() => {
    // Show switch account modal when user is redirected from invitation email
    // and is already logged in
    if (user && urlEmail && user.email !== urlEmail) openSwitchAccountsModal()
  }, [urlEmail, user])

  return (
    <AuthPageWrapper>
      <Login />
    </AuthPageWrapper>
  )
}

export default LoginPage
